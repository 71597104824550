import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
import { VirtualElementDemo } from '../../../components/Demos';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "virtual-elements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#virtual-elements",
        "aria-label": "virtual elements permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual Elements`}</h1>
    <p>{`You can use a virtual element instead of a real DOM element as the reference.
This allows you to position the popper relative to a virtual area defined by any
coordinates you desire.`}</p>
    <p>{`A common use case for this is making the popper follow the mouse cursor, where
the cursor acts as a point reference.`}</p>
    <p>{`A virtual element is a plain object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "flow"
    }}><pre parentName="div" {...{
        "className": "language-flow"
      }}><code parentName="pre" {...{
          "className": "language-flow"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` VirtualElement `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token flow-punctuation punctuation"
          }}>{`{|`}</span>{`
  getBoundingClientRect`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ClientRect `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` DOMRect`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  contextElement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token flow-punctuation punctuation"
          }}>{`|}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`contextElement`}</code>{` is an optional property that describes the DOM context of the
virtual element. This is necessary if `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getBoundingClientRect`}</code>{` is derived from a
real DOM element, and that element is within a different scrolling container
context to the popper element.`}</p>
    <h2 {...{
      "id": "demo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#demo",
        "aria-label": "demo permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Demo`}</h2>
    <VirtualElementDemo mdxType="VirtualElementDemo" />
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usage`}</h2>
    <p>{`This will make the popper follow the mouse cursor as seen in the demo above:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateGetBoundingClientRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    top`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    right`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    bottom`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    left`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` virtualElement `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  getBoundingClientRect`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateGetBoundingClientRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` instance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`virtualElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mousemove'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` clientX`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` clientY`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` y `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  virtualElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`getBoundingClientRect `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`generateGetBoundingClientRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  instance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      